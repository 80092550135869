export const callTooltip = 'Start call'
export const appointmentTooltip = 'Schedule appointment'
export const emailTooltip = 'Send email'
export const messageTooltip = 'Send message'
export const offlineTooltip = 'Offline'
export const agentsOfflineTooltip = 'Agents offline'
export const Production = 'production'
export const FILE_SIZE_LIMIT = 2 * 1024 * 1024 // 2MB in bytes
export const workbotSeoName = "workbot"
export const workbotNewSeoName = "get-started"

export const PRO_PLAN_MONTHLY_SLUG = 'pro-plan-monthly'
